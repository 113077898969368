import moment from 'moment';
import { getUUID, email2UID } from 'shared/util/utils';
import { codingbarApi } from 'codingbar-api';
import {
	AI_MATE_EMAIL_VERIFICATIONS_COLLECTION,
	AI_MATE_USER_TRIALS_COLLECTION,
	AI_MATE_SUBSCRIPTION_PLANS_COLLECTION,
	AI_MATE_USER_SUBSCRIPTIONS_COLLECTION,
	AI_MATE_CHAT_GPT_QUESTION_COUNT_COLLECTION,
} from './collections';

export const AImateUserTrialsDays = 15; // 30 days

export const getAiMateEmailVerification = (filter) => {
	const projection = {};
	return codingbarApi.getCoreService().queryOne(AI_MATE_EMAIL_VERIFICATIONS_COLLECTION, filter, projection);
};

export const patchAiMateEmailVerification = (data) => {
	const filter = {
		uid: data.uid,
	};

	return codingbarApi.getCoreService().patchData(AI_MATE_EMAIL_VERIFICATIONS_COLLECTION, filter, data);
};

export const createAImateUserTrial = (email, name) => {
	if (!email) {
		return Promise.reject(new Error('createAImateUserTrial: email is empty!'));
	}
	const now = moment();
	const nowTimestamp = now.valueOf();
	const data = {
		id: `${getUUID()}-${nowTimestamp}`,
		uid: email2UID(email),
		userEmail: email,
		name,
		startDate: nowTimestamp,
		endDate: now.add(AImateUserTrialsDays, 'days').valueOf(),
		status: 'active',
	};

	return codingbarApi.getCoreService().createData(AI_MATE_USER_TRIALS_COLLECTION, data);
};

export const fetchAImateChatGPTQuestionCount = async (uid) => {
	if (!uid) {
		return Promise.reject(new Error('fetchAImateChatGPTQuestionCount: uid is empty!'));
	}
	const today = moment().format('YYYY/MM/DD');
	const filter = {
		uid,
		date: today,
	};
	const projection = {
		_ip: false,
		_id: false,
	};
	const res = await codingbarApi
		.getCoreService()
		.queryOne(AI_MATE_CHAT_GPT_QUESTION_COUNT_COLLECTION, filter, projection);
	const userChatGPTQuestionCount = res.status === 'success' ? res.result : null;
	if (!userChatGPTQuestionCount) {
		const data = {
			id: `${getUUID()}-${Date.now()}`,
			uid,
			date: today,
			count: 0,
		};
		await codingbarApi.getCoreService().createData(AI_MATE_CHAT_GPT_QUESTION_COUNT_COLLECTION, data);
		return data;
	}
	return userChatGPTQuestionCount;
};

export const updateAImateChatGPTQuestionCount = async (AImateChatGPTQuestionCountObject) => {
	if (!AImateChatGPTQuestionCountObject) return Promise.resolve();

	const filter = {
		uid: AImateChatGPTQuestionCountObject.uid,
		date: AImateChatGPTQuestionCountObject.date,
	};

	const data = {
		count: AImateChatGPTQuestionCountObject.count + 1,
		updated: Date.now(),
	};

	await codingbarApi.getCoreService().updateData(AI_MATE_CHAT_GPT_QUESTION_COUNT_COLLECTION, filter, data);
	return {
		...AImateChatGPTQuestionCountObject,
		count: AImateChatGPTQuestionCountObject.count + 1,
		updated: Date.now(),
	};
};

export const fetchAImateUserTrial = async (uid) => {
	if (!uid) {
		return Promise.reject(new Error('fetchAImateUserTrial: uid is empty!'));
	}

	const filter = {
		uid,
		status: 'active',
	};

	const sort = {
		created: -1,
	};

	const projection = {
		_ip: false,
		_id: false,
	};

	const distinctKey = '';
	const skip = 0;
	const limit = 1;

	const res = await codingbarApi
		.getCoreService()
		.query(AI_MATE_USER_TRIALS_COLLECTION, filter, sort, projection, distinctKey, skip, limit);
	console.log('AI_MATE_USER_TRIALS_COLLECTION', res.result);
	return res.status === 'success' ? res.result[0] : null;
};

export const fetchAImateSubscriptionPlans = () => {
	return codingbarApi.getCoreService().queryData(
		AI_MATE_SUBSCRIPTION_PLANS_COLLECTION,
		{ plan: 'pro' },
		{},
		{
			_ip: false,
			_id: false,
		},
		''
	);
};

export const fetchAImateUserCurrentSubscription = async (uid) => {
	if (!uid) {
		return Promise.reject(new Error('fetchAImateUserTrial: uid is empty!'));
	}

	const filter = {
		uid,
	};

	const sort = {
		created: -1,
	};

	const projection = {
		_ip: false,
		_id: false,
	};

	const distinctKey = '';
	const skip = 0;
	const limit = 1;

	const res = await codingbarApi
		.getCoreService()
		.query(AI_MATE_USER_SUBSCRIPTIONS_COLLECTION, filter, sort, projection, distinctKey, skip, limit);

	console.log('AI_MATE_USER_SUBSCRIPTIONS_COLLECTION', res.result);
	return res.status === 'success' ? res.result[0] : null;
};

export const getAImateUserCurrentSubscriptionPlan = async (uid, AImateSubscriptionPlans) => {
	const [AImateUserTrail, AImateUserCurrentSubscription] = await Promise.all([
		fetchAImateUserTrial(uid),
		fetchAImateUserCurrentSubscription(uid),
	]);

	// console.log('SSS>>>>AImateUserTrail:', AImateUserTrail);
	// console.log('SSS>>>>AImateUserCurrentSubscription:', AImateUserCurrentSubscription);
	// console.log('SSS>>>>AImateSubscriptionPlans:', AImateSubscriptionPlans);

	// 已付款訂閱
	if (
		AImateUserCurrentSubscription &&
		AImateUserCurrentSubscription.tradeRecords &&
		AImateUserCurrentSubscription.tradeRecords.length > 0 &&
		AImateUserCurrentSubscription.tradeRecords.find((tradeRecord) => tradeRecord.status === 'purchased')
	) {
		return {
			subscription: AImateUserCurrentSubscription,
			status: 'purchased',
			plan:
				AImateSubscriptionPlans.find((plan) => plan.id === AImateUserCurrentSubscription.planId) ||
				AImateSubscriptionPlans.find((plan) => plan.id === AImateUserCurrentSubscription.originId),
		};
	}
	// return {
	// 	subscription: AImateUserCurrentSubscription,
	// 	status: 'unpaid',
	// 	plan: AImateSubscriptionPlans.find((plan) => plan.plan === 'free'),
	// };
	if (AImateUserTrail) {
		return {
			trial: AImateUserTrail,
			status: 'trial',
			plan: AImateSubscriptionPlans.find((plan) => plan.plan === 'pro'),
		};
	}

	// 取消訂閱、付款失敗、未付款
	return {
		status: 'free',
		plan: AImateSubscriptionPlans.find((plan) => plan.plan === 'free'),
	};
};

export const unSubscribeAImatePlan = async (id) => {
	var requestOptions = {
		body: JSON.stringify({}),
		cache: 'no-cache',
		headers: {
			'content-type': 'application/json',
		},
		method: 'POST', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, cors, *same-origin
		redirect: 'follow', // manual, *follow, error
		referrer: 'no-referrer', // *client, no-referrer
	};

	const response = await fetch(`https://api3.codingbar.ai/service/payment/subscriptions/${id}/cancel`, requestOptions);

	if (response) {
		const result_1 = await response.json();
		return await Promise.resolve(result_1);
	}

	return Promise.reject('unSubscribeAImatePlan error');
};
